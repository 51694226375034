// the shows displayed in home page
export const futureShows = [
  {
    year: 2025,
    date: '15 FEB 2025',
    event: '',
    venue: 'An Club',
    withBands: 'Chronoboros, Bacchus Priest',
    city: 'Athens',
    country: 'GR',
  },
  {
    year: 2025,
    date: '22 MAY 2025',
    event: '',
    venue: 'Eightball',
    withBands: 'Dvne',
    city: 'Thessaloniki',
    country: 'GR',
  },
  {
    year: 2025,
    date: '23 MAY 2025',
    event: '',
    venue: 'Skyland',
    withBands: 'Dvne',
    city: 'Larissa',
    country: 'GR',
  },
  {
    year: 2025,
    date: '24 MAY 2025',
    event: '',
    venue: 'Gazarte',
    withBands: 'Dvne',
    city: 'Athens',
    country: 'GR',
  },
];

export const shows = [
  {
    year: 2023,
    date: '31 MAY - 9 JUN 2024',
    event: 'Summer Tour 2024',
    venue: '',
    withBands: '',
    city: '7 shows in FR',
    country: 'BE, NL, DE, RO',
  },
  {
    year: 2024,
    date: '11 MAY 2024',
    event: '"Omonoia" & "Never" 10 year anniversary show',
    venue: 'Fuzz',
    city: 'Athens',
    country: 'GR',
    withBands: 'Universe217',
  },
  {
    year: 2024,
    date: '25 MAR 2024',
    event: '',
    venue: 'Gagarin 205',
    city: 'Athens',
    country: 'GR',
    withBands: 'Botch',
  },
  {
    year: 2023,
    date: '13 - 21 OCT 2023',
    event: 'Commotion Tour',
    venue: '',
    city: '8 shows in NL',
    country: 'BE, DE',
    withBands: 'Coltaine',
  },
  {
    year: 2023,
    date: '4 AUG 2023',
    event: 'Rockstadt Extreme Fest',
    venue: '',
    city: 'Rasnov',
    country: 'RO',
    withBands:
      'Meshuggah, Arch Enemy, In Flames, Architects, Zeal & Ardor, The Ocean, While She Sleeps, Employed to Serve, Villagers of Ioannina City and more',
  },
  {
    year: 2023,
    date: '16 JUL 2023',
    event: 'New Long Fest',
    venue: '',
    city: 'Nea Makri',
    country: 'GR',
    withBands:
      'Sakis Tolis, Abyssus, Blame Kandinsky, Mammock, Euphrosyne, The Vulcan Itch',
  },
  {
    year: 2023,
    date: '10 - 26 MAY 2023',
    event: 'Commotion Tour 2023',
    venue: '',
    withBands: 'Their Methlab',
    city: '14 shows in GR',
    country: 'SRB, BG, RO, HU, CZ, AT, BE',
  },
  {
    year: 2022,
    date: '22 JUL 2022',
    event: 'Rockwave Festival',
    venue: 'Terra Vibe',
    city: 'Malakasa',
    country: 'GR',
    withBands: 'Social Distortion, Amenra, Krotalias, Half Gramme of Soma',
  },
  {
    year: 2022,
    date: '7 MAY 2022',
    event: '',
    venue: 'An Club',
    city: 'Athens',
    country: 'GR',
    withBands: 'Their Methlab, Khirki',
  },
  {
    year: 2020,
    date: '8 MAR 2020',
    event: '',
    venue: 'Gagarin 205',
    city: 'Athens',
    country: 'GR',
    withBands: 'Riverside, Verbal Delirium',
  },
  {
    year: 2019,
    date: '10 OCT 2019',
    event: '',
    venue: 'Temple',
    city: 'Athens',
    country: 'GR',
    withBands: 'Deaf Kids, Mavro Gala',
  },
  {
    year: 2019,
    date: '13 APR 2019',
    event: '',
    venue: 'Temple',
    city: 'Athens',
    country: 'GR',
    withBands: 'Harakiri for the Sky, Message in a Cloud',
  },
  {
    year: 2018,
    date: '25 OCT 2018',
    event: '',
    venue: 'Kyttaro',
    city: 'Athens',
    country: 'GR',
    withBands: 'Minsk',
  },
  {
    year: 2017,
    date: '27 APR 2017',
    event: '',
    venue: 'Fuzz',
    city: 'Athens',
    country: 'GR',
    withBands: 'Toundra, Their Methlab',
  },
  {
    year: 2015,
    date: '26 APR 2015',
    event: '',
    venue: 'Kyttaro',
    city: 'Athens',
    country: 'GR',
    withBands: 'Altar of Plagues, Year of No Light, Diablery',
  },
  {
    year: 2014,
    date: '28 MAY 2014',
    event: '',
    venue: 'Gagarin 205',
    city: 'Athens',
    country: 'GR',
    withBands: 'Deafheaven',
  },
  {
    year: 2014,
    date: '13	AUG 2014',
    city: 'Rasnov',
    country: 'RO',
    venue: '',
    event: 'Rockstadt Extreme Fest',
    withBands:
      'Obituary, Behemoth, Sodom, Katatonia, Rotting Christ, Fleshgod Apocalypse, Misery Index, The Agonist, Mors Principium Est, Infected Rain, the Lumberjack Feedback, and more',
  },
];
